<template>
  <f-page :title="$t(`labels.${this.$route.name}`)">
    <f-form @submit="onSubmitSearch">
      <f-card>
        <f-row>
          <f-col
            lg="8"
            md="12"
            sm="12"
          >
            <f-form-group
              class="mb-0"
              :label="$t('labels.endDateRange')"
            >
              <f-row>
                <f-col>
                  <f-input
                    v-model="search.startDate"

                    type="date"
                  />
                </f-col>
                <f-col>
                  <f-input
                    v-model="search.endDate"
                    type="date"
                  />
                </f-col>
              </f-row>
            </f-form-group>
          </f-col>
          <f-col
            lg="4"
            md="12"
            sm="12"
          >
            <f-select
              v-model="search.status"
              :label="$t('labels.status')"
              :options="statuses"
              value-type="string"
            />
          </f-col>
        </f-row>
        <f-row>
          <f-col>
            <f-switch
              v-model="search.isAllCompany"
              :false-label="$t('labels.allCompanies')"
              :true-label="$t('labels.allCompanies')"
            />
          </f-col>
        </f-row>
        <template
          #footer
        >
          <f-button
            class="float-right"
            icon="search"
            :label="$t('labels.search')"
            type="submit"
            variant="primary"
          />
          <f-button
            class="float-right mr-2"
            icon="eraser"
            t-label="reset"
            variant="light"
            @click="clearSearch"
          />
        </template>
      </f-card>
    </f-form>

    <f-card class="table-view">
      <f-datatable
        ref="featureReportDatatable"
        class="feature-report-datatable table-center"
        :columns="featureReportColumns"
        data-url="/features"
        :features="{ serverSidePagination: true, serverSideExport: true }"
        :query-params="search"
      />
    </f-card>
  </f-page>
</template>

<script>
import {
  featureReportColumns,
} from '@/data/columns'

import { featureStatus, userRole } from '@/data/enums'
import { mapGetters } from 'vuex'

export default {

  data() {
    return {
      featureReportColumns,
      userRole,
      search: {
        isReportQuery: true,
      },
      files: [],
      statuses: [
        {
          id: featureStatus.WAITING,
          name: this.$i18n.t('labels.waitingFeature')
        },
        {
          id: featureStatus.APPROVED,
          name: this.$i18n.t('labels.approvedFeature')
        },
        {
          id: featureStatus.IN_PROGRESS,
          name: this.$i18n.t('labels.inProgress')
        },
        { id: featureStatus.TESTING, name: this.$i18n.t('labels.testing') },
        {
          id: featureStatus.TEST_APPROVED,
          name: this.$i18n.t('labels.testApproved')
        },
        {
          id: featureStatus.DONE,
          name: this.$i18n.t('labels.done')
        },
        {
          id: featureStatus.REJECTED,
          name: this.$i18n.t('labels.rejected')
        },
      ]
    }
  },

  computed: {
    ...mapGetters(['user', 'selectedProjectId', 'selectedCompanyId']),
    featureProjectName() {
      return this.selectedFeature.project
        ? ` - ${this.selectedFeature.project.name}`
        : ''
    },
    isPaginationEnabled() {
      return !['pendingFeatures'].includes(this.$route.name)
    },
  },
  created() {
    this.search.projectId = this.selectedProjectId
    this.search.companyId = this.selectedCompanyId
  },
  methods: {
    onSubmitSearch() {
      this.$refs.featureReportDatatable.refresh()
    },
    clearSearch() {
      this.search = { projectId: this.selectedProjectId, isReportQuery: true }
    },
  }
}
</script>

<style lang="scss">
.dragDrop .custom-file-label {
  height: 100px;
  line-height: 100px;
}
.dragDrop .custom-file,
.dragDrop .custom-file-input {
  height: 100px;
}

.feature-report-datatable {
  table {
    border-left: none;
    border-right: none;
    th {
      border-left: none;
      border-right: none;

      &:nth-of-type(3) {
        text-align: start;
      }
      &:nth-of-type(4) {
        text-align: start;
      }

      &:nth-of-type(6) {
        padding-left: 15px !important;

      }
    }
    tbody {
      & > tr {
        border-left: none;
        border-right: none;
        border-top: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        & > td {
          border: none;

          &:nth-of-type(3) {
            text-align: start;
          }
          &:nth-of-type(4) {
            text-align: start;
          }

          &:nth-of-type(5) {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
.table-view {
  .card-body {
    padding: 1rem 0;
  }
  .fixed-table-toolbar {
    padding: 0 1.5rem;
  }
  .fixed-table-pagination{
    padding: 0 1.5rem;
  }

}
</style>
